<template>
 
  
  <div class="page">
     


    <template v-if="is_loading">
      Loading...
    </template>
    <template v-else-if="!token_data">

      <div class="wrong">
        <div>Wrong link</div>  
      </div>
    </template>

    <template v-else>
       
      <div class="wrong">
        <div>Email unsubscribed</div>
            
        <UiButton
            :name="'Go to home page'"
            :tag="'NuxtLink'"
            :type="'primary'"
            to="/"
            />     
      </div>
  
    </template>

    
  </div>  

</template>

<script setup lang="ts">

definePageMeta({
  layout: "short",
  auth: false
})

const password = ref('')
const passwordRepeat = ref('')
const router = useRouter();
const { $user, $popup, $api } = useNuxtApp();
const isLoding = ref(false)
const key_token = ref(router.currentRoute.value.query.k)
 


const loadData = async (k,e) => {
  return await $api.getEmailUnsubscribeToken(k,e);  
}


const { pending: is_loading,  data: token_data, error: token_error } = await useLazyAsyncData('token_data', async () => await loadData(key_token.value))




</script>


<style scoped>
.page{
  padding: 2rem;
} 
.actions{
  /* margin-top: 10px; */
}
.actions > *{
  margin-right: 10px;
}

.wrong{
  text-align: center;
}
</style>
